.share-acceptable-use-policy {
    font-family: 'Fredoka', cursive;
    font-size: 16px;
    color: #FFFFFF;
    opacity: 0.5;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  
  .share-acceptable-use-policy a {
    color: inherit; 
  }
