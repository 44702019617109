.use-policy-container {
  font-family: 'Fredoka', cursive;
  text-align: center;
  padding: 0 20px 0 20px;
}

.use-policy-header {
  font-size: 32px;
  font-weight: 700;
  margin: 24px 0 32px 0;
}

.use-policy-subheader {
  font-size: 16px;
  font-weight: 700;
  display: table;
  margin: 0 auto;
  text-align: left;
  padding-bottom: 20px;
}

.use-policy-terms {
  display: table;
  margin: 0 auto;
  text-align: left;
  padding: 15px 30px 15px 40px;
  hyphens: auto;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 24px;
}

.use-policy-terms li {
  padding: 3px 0 3px 0;
}