.page {
  text-align: center;
  padding-bottom: 0;
}

.page-header {
  min-height: 100%;
  color: white;
  overflow-y: scroll;
}

@media screen and (max-height: 725px) {
  .page-header {
    height: 640px;
    overflow: auto;
  }
}

.page-logo {
  height: 64px;
  width: 154px;
  margin-top: 32px;
}