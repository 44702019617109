@import url('https://fonts.googleapis.com/css?family=Fredoka');

.media-component-content {
  display: block;
  margin: 32px 0 0;
}

.media-component-error-container {
  margin: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.media-component-image,
.media-component-video {
  z-index: 10;
  width: auto;
  height: auto;
  max-width: 90%;
  max-height: 400px;
  pointer-events: none;
  border-radius: 16px;
  border-bottom-style: solid;
  border-bottom-width: 5px;
  border-bottom-color: #5000BE;
}

.media-component-image-error {
  z-index: 10;
  min-width: 150px;
  height: auto;
  max-width: 10%;
  max-height: 400px;
  pointer-events: none;
}

.media-component-button {
  z-index: 20;
  position: relative;
  height: 96px;
  width: 96px;
  margin: -42px -10px -7px;
}

@media screen and (max-height: 725px) {
  .media-component-content {
    overflow: auto;
  }
}

.media-component-error {
  font-family: 'Fredoka', cursive;
  font-size: 32px;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 0;
  margin-bottom: 0;
}

.media-component-loading {
  font-family: 'Fredoka', cursive;
  font-size: 16px;
  margin: 12px 0 0 0;
}
