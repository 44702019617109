@import url('https://fonts.googleapis.com/css?family=Fredoka');

.media-instruction-title {
  font-family: 'Fredoka', cursive;
  font-size: 40px;
  margin: 0;
  padding: 0 0 48px 0;
}

.media-instruction-message {
  font-family: 'Fredoka', cursive;
  font-weight: normal;
  font-size: 20px;
  margin: 12px 0 48px 0;
  white-space: pre-wrap;
}
